@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family="Barlow", sans-serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --main-color: #b06bf3;
    --background: #00001C;
  }

  * {
    scroll-margin-top: 50px;
  }
  
  body{
    font-family:"Exo", sans-serif ;
    background-color:#00001C ;
}
::-webkit-scrollbar {
    width: 8px; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #b06bf3; 
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
  }